import React, { useEffect, useContext, useState } from 'react'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'

function ModalConfirm({task, status, setTask, setLoading}) {  
  const {request} = useHttp()
  const {token} = useContext(AuthContext)

  const updateStatus = (task, status) => async () => {
    console.log(`Update status: ${task.OperationID} to ${status.name}`)       
    try {
      setLoading(true)
      const updatedTask = await request(
        '/api/tasks',
        'POST',
        {
          OperationID: task.OperationID,
          OperationStatusID: status.id
        }, 
        {
          Authorization: `Bearer ${token}`
        })
      setTask(updatedTask)
      setLoading(false)
    } catch (e) {setLoading(false)}
  } 

  return (
    <>     
      <div id={"modal1" + task.OperationID + status.id} className="modal modal-fixed-footer ">
      <div className="modal-content left-align">
        <h4>{task.ACReg}</h4>
        <p>{task['НаименованиеАнгл']}</p>
        <p>New status: In process {status.name}</p>
      </div>
      <div className="modal-footer row valign-wrapper ">   
          <div className="col s6 center-align ">
              <a href="#!" className="modal-close waves-effect waves-green green btn" onClick={updateStatus(task, status)}>Confirm</a>
          </div>
          <div className="col s6 center-align ">
              <a href="#!" className="modal-close waves-effect waves-red red modal-close btn">Cancel</a>
          </div>
      </div>
    </div>
    </>
  );
}

export const ChangeStatusButton = ({task, setTask}) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    var elems = document.querySelectorAll('.dropdown-trigger');
    window.M.Dropdown.init(elems);
  })

  useEffect( () => {
    let elems = document.querySelectorAll('.modal');
    window.M.Modal.init(elems, {
      opacity: 0.5,
      inDuration: 250})
  })

  const allStatus = [
    {id: 1, name: '0%'},
    {id: 2, name: '25%'},
    {id: 3, name: '50%'},
    {id: 4, name: '75%'},
    {id: 5, name: '95%'}
  ]

  let buttonName =  'Update' 

  if (loading === true) {
    buttonName = 'Sending'
  } else if (task.OperationStatusID > 4) {
    buttonName = 'Done'
  }

  const availableStatus = allStatus.filter(s => s.id > task.OperationStatusID)

  return (
    <>      
      <a 
        className={'dropdown-trigger waves-effect waves-light btn-large blue darken-1 z-depth-2 updateButton ' 
                   + (task.OperationStatusID > 4 || loading ? 'disabled' : '')} 
        href='#!' 
        data-target={'dropdown-' + task.OperationID}
      > 
        { buttonName }
      </a>

      <ul id={'dropdown-' + task.OperationID} className='dropdown-content'>
        {availableStatus.map((status, index) => {
          return (
            <li key={index}>
              <a className='black-text center-align modal-trigger'
                style={{'marginRight':'auto'}} 
                href={"#modal1" + task.OperationID + status.id}
              >
                {status.name}
              </a>
            </li>
          )
        })}
      </ul>

      {availableStatus.map((status, index) => {
        return (
          <ModalConfirm key={index} task={task} status={status} setTask={setTask} setLoading={setLoading}></ModalConfirm>
        )
      })}

    </>
)}