import React, {useContext, useEffect, useState} from "react"
import { AuthContext } from "../context/AuthContext"
import {useHttp} from '../hooks/http.hook'
import { useMessage } from "../hooks/message.hook"

export const AuthPage = () => {
  const auth = useContext(AuthContext)
  const message = useMessage()
  const {loading, request, error, clearError} = useHttp()
  const [form, setForm] = useState({
    email: '', password: ''
  })
  
  useEffect(() => {
    message(error)
    clearError()
  }, [error, message, clearError])

  useEffect(() => {
    //window.M.updateTextFields()
  }, [])

  const changeHandler = event => {
    setForm({ ...form, [event.target.name]: event.target.value})
  }

  const loginHandler = async () => {
    try {
      const data = await request('/api/auth/login', 'POST', {...form})
      auth.login(data.token, data.userId)
    } catch (e) {
      
    }
  }

  return (
    <div className="row" style={{maxWidth: '320px'}}>
      <div className="col center">
      <h3>JetPort Technics</h3>
      <div className="card">
        <div className="card-content card-login">
          <span className="card-title">Login page</span>
          <div>            
            <div className="input-field">
              <input 
                placeholder="Enter email" 
                id="email" 
                type="email" 
                name="email"
                className="blue-input validate"
                value={form.email}
                onChange={changeHandler}
                style={{textAlign: 'center'}}
              />
             {/* <label htmlFor="email">Email</label>*/}
            </div>
            <div className="input-field">
              <input 
                placeholder="Enter password" 
                id="password" 
                type="password" 
                name="password"
                className="blue-input"
                value={form.password}
                onChange={changeHandler}
                style={{textAlign: 'center'}}
              />
              {/*<label htmlFor="password">Password</label>*/}
            </div>
          </div>
        </div>
        <div className="card-action">
          <button 
            className="btn blue" 
            style={{marginRight: 10}}
            disabled={loading}
            onClick={loginHandler}
          >
            Sign In
          </button>
        </div>
      </div>
      </div>
    </div>
  )
}