import React from 'react'
import { Task } from './Task'

export const TasksList = ({ tasks, fetchTasks }) => {
  
  if (!tasks.length) {
    return <p className="center">No tasks available</p>
  }

  return (
    <> 
      {tasks.map((task, index) => <Task task={task}/>)}
    </>
  )
}