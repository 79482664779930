import React, {useState, useEffect} from 'react'
import { ChangeStatusButton } from './ChangeStatusButton'

export const Task = ({ task : curTask }) => {
  const [task, setTask] = useState(curTask) 
  
  useEffect( () => {
    setTask(curTask)
  }, [setTask, curTask])

  return (
    
    <div className="row" key={task.OperationID}>
      <div className="col s12 m12 l12">
        <div className="card" style={{marginBottom: "auto"}}>
          <div className="card-content">
            <span className="card-title">{task.ACReg}</span>
            <p>WPSS №: {task.WPSSId}</p>
            <p>{task["НаименованиеАнгл"]}</p>
          </div>
          <div className="card-action valign-wrapper" style={{padding: "10px"}}>
            <div className="col s6">{task.OperationStatus}</div>
            <div className="col s6 right-align">                    
              <ChangeStatusButton task={task} setTask={setTask}></ChangeStatusButton>                         
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}