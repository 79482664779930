import React, { useCallback, useContext, useState, useEffect } from "react"
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import {Loader} from '../components/Loader'
import {TasksList} from '../components/TasksList'
import {useHistory} from 'react-router-dom'

export const TasksPage = () => {
  const history = useHistory()
  const [tasks, setTasks] = useState([])
  const {loading, request} = useHttp()
  const {logout, token} = useContext(AuthContext)

  const fetchTasks = useCallback(async () => {
    try {
      const fetched = await request('/api/tasks', 'GET', null, {
        Authorization: `Bearer ${token}`
      })
      console.log(fetched)
      setTasks(fetched.tasks)
    } catch (e) {
      logout()
      history.push('/')
    }
  }, [token, history, logout, request])

  useEffect( () => {
    fetchTasks()
  }, [fetchTasks])

  if (loading) {
    return <Loader />
  }

  return (
    <>
      {!loading && <TasksList tasks={tasks} setTasks={setTasks}/>}
    </>
  )
}