import React, { useContext } from 'react'
import {useHistory} from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'

export const Navbar = () => {
  const history = useHistory()
  const auth = useContext(AuthContext)
  
  const logoutHandler = event => {
    event.preventDefault()
    auth.logout()
    history.push('/')
  }

  return (
    <>
     <nav>
      <div className="nav-wrapper blue darken-1">
        <a href="#!" className="brand-logo left">Jetport Technics</a>
        <a href="/" className="right logout" onClick={logoutHandler}>Logout</a>
      </div>
      </nav>
    </>
  )

}